var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "transparent", tile: "", elevation: "0" } },
    [
      _c(
        "v-card",
        {
          staticClass: "py-sm-8 py-4",
          attrs: { color: "light_gray", tile: "", elevation: "0" },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "11", lg: "9" } }, [
                _c("span", { staticClass: "text-h5 font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("label.contactUs"))),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-8", attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11", lg: "9" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-6",
                  attrs: { color: "transparent", tile: "", elevation: "5" },
                },
                [
                  _c("div", {
                    staticClass: "px-sm-6",
                    domProps: { innerHTML: _vm._s(_vm.htmlContent) },
                  }),
                  _c(
                    "div",
                    { staticClass: "px-sm-6 pt-2 contactUs-form" },
                    [
                      _c(
                        "v-form",
                        { ref: "contactUsForm" },
                        [
                          _c("app-form-field", {
                            attrs: {
                              placeholder: _vm.$t("label.name"),
                              rules: _vm.$validation.nameRules(),
                            },
                            model: {
                              value: _vm.contactUs.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contactUs,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contactUs.name",
                            },
                          }),
                          _c("app-form-field", {
                            attrs: {
                              placeholder: _vm.$t("label.email"),
                              rules: _vm.$validation.emailRules(),
                            },
                            model: {
                              value: _vm.contactUs.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contactUs,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contactUs.email",
                            },
                          }),
                          _c("app-form-field", {
                            attrs: {
                              prefix: _vm.contactUs.mobileNumberCountryCode,
                              placeholder: _vm.$t("label.phone"),
                              rules: _vm.$validation.mobileNumberRules(),
                            },
                            model: {
                              value: _vm.contactUs.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contactUs,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contactUs.phone",
                            },
                          }),
                          _c("app-form-field", {
                            attrs: {
                              type: "textarea",
                              placeholder: _vm.$t("label.message"),
                              rules: _vm.$validation.requiredRules(),
                            },
                            model: {
                              value: _vm.contactUs.message,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contactUs,
                                  "message",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contactUs.message",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "rounded-lg font-weight-bold text-body-1 py-6 white--text mt-1",
                          attrs: { block: "", color: "black" },
                          on: { click: _vm.send },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("label.send")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }